import React from "react"
import { Link } from "gatsby"
import { Container } from "react-bootstrap"
import Layout from "../components/layout/layout"
import Style from "../pages-style/about.module.scss"
import Typist from "react-typist"

const AboutPage = () => (
  <Layout>
    <Container>
      <div className={Style.main}>
        <h1>はじめまして</h1>
        <Typist avgTypingDelay={70} cursor={{ hideWhenDone: true }}>
          <span>
            ベンチャー企業でアプリケーションエンジニアをしているSHINGUです
          </span>
          <br />
          <Typist.Delay ms={600} />
          <span>趣味でWEBアプリケーションを作っています。</span>
          <br />
          <Typist.Delay ms={600} />
          <span>
            ビジネス番組で好きでカンブリア宮殿とガイアの夜明けが大好きです!
          </span>
          <br />
          <span>GatsbyJSでLPやサイトを爆速で作れないか模索中</span>
        </Typist>
        <Link to="/">TOPページに戻る</Link>
      </div>
    </Container>
  </Layout>
)

export default AboutPage
